<template>
  <div class="container mx-auto max-w-7xl">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
