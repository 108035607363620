<template>
  <v-container class="py-12 px-4 xl:px-0">
    <section
      class="
        flex flex-col
        lg:flex-row
        items-center
        justify-between
      "
    >
      <div class="lg:w-1/2">
        <h1 class="text-4xl md:text-6xl font-black mb-6 text-gray-800">
          Pash pune masina in miscare
        </h1>
        <p class="text-xl md:text-3xl mb-6 text-gray-600">
          Te ajutăm să găsești piesa de care ai nevoie, simplu și rapid ! Tu
          completezi chestionarul, iar noi căutam piesa potrivită pentru tine !
        </p>
      </div>
      <div class="flex-1 w-full lg:w-auto p-6 my-6 rounded shadow bg-white">
        <add-rfq-item-form @create:item="addItem($event)"></add-rfq-item-form>
      </div>
    </section>
  </v-container>
</template>

<script>
import VContainer from "./VContainer.vue";
import AddRfqItemForm from "./forms/AddRfqItemForm.vue";

export default {
  name: "Home",
  components: {
    AddRfqItemForm,
    VContainer,
  },
  methods: {
    addItem(item) {
      localStorage.setItem("part", JSON.stringify(item));
      this.$router.push({
        name: "rfqs.create",
      });
    },
  },
};
</script>
