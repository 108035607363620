class CartItem {
  item_id = "";
  name = "";
  price = "";
  quantity = 1;
  position = 1;

  constructor(id, name, price, quantity = 1) {
    this.item_id = id;
    this.name = name;
    this.price = price;
    this.quantity = quantity;
  }

  subtotal() {
    return (
      Math.round((this.price * this.quantity + Number.EPSILON) * 100) / 100
    );
  }
}

export default CartItem;
