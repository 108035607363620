<template>
  <footer class="bg-gray-800 pt-5">
    <v-container class="text-gray-800 flex flex-wrap justify-left">
      <!-- Col-1 -->
      <div class="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        <!-- Col Title -->
        <div class="text-xs uppercase text-gray-400 font-medium mb-6">
          Pentru vanzatori
        </div>

        <!-- Links -->
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Devino partener
        </a>
      </div>

      <!-- Col-2 -->
      <div class="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        <!-- Col Title -->
        <div class="text-xs uppercase text-gray-400 font-medium mb-6">
          Pentru cumparatori
        </div>

        <!-- Links -->
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Ce este PASH
        </a>
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Cum functioneaza
        </a>
      </div>

      <!-- Col-3 -->
      <div class="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        <!-- Col Title -->
        <div class="text-xs uppercase text-gray-400 font-medium mb-6">
          Pash.ro
        </div>

        <!-- Links -->
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Termeni si conditii
        </a>
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Politica de confidentialitate
        </a>
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          GPDR
        </a>
      </div>

      <!-- Col-3 -->
      <div class="p-5 w-1/2 sm:w-4/12 md:w-3/12">
        <!-- Col Title -->
        <div class="text-xs uppercase text-gray-400 font-medium mb-6">
          Unde ne gasiti
        </div>

        <!-- Links -->
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Facebook
        </a>
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Instagram
        </a>
        <a
          href="#"
          class="
            my-3
            block
            text-gray-300
            hover:text-gray-100
            text-sm
            font-medium
            duration-700
          "
        >
          Twitter
        </a>
      </div>
    </v-container>

    <!-- Copyright Bar -->
    <div
      class="
        flex
        pb-5
        px-3
        m-auto
        pt-5
        border-t border-gray-500
        text-gray-400 text-sm
        flex-col
        md:flex-row
        max-w-7xl
      "
    >
      <div class="mt-2">Copyright © 2021 Pash. Toate drepturile rezervate.</div>

      <!-- Required Unicons (if you want) -->
      <div class="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
        <a href="#" class="w-6 mx-1">
          <i class="uil uil-facebook-f"></i>
        </a>
        <a href="#" class="w-6 mx-1">
          <i class="uil uil-twitter-alt"></i>
        </a>
        <a href="#" class="w-6 mx-1">
          <i class="uil uil-youtube"></i>
        </a>
        <a href="#" class="w-6 mx-1">
          <i class="uil uil-linkedin"></i>
        </a>
        <a href="#" class="w-6 mx-1">
          <i class="uil uil-instagram"></i>
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import VContainer from "../components/VContainer.vue";

export default {
  name: "Footer",
  components: { VContainer },
};
</script>

<style></style>
