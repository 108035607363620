import cartsApi from "../api/resources/cartsApi";
import store from "../store";

export default {
  create(data) {
    const token = this.instance();

    let promisedResponse = {};

    if (token) {
      promisedResponse = cartsApi.storeItems(token, data);
    } else {
      promisedResponse = cartsApi.store(data);
    }

    return new Promise((resolve, reject) => {
      promisedResponse
        .then((response) => {
          this._storeCatItemCount(response);

          this.saveInstance(response.id);
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.destroyInstance();
          }
          reject(error)
        });
    });
  },

  show() {
    return new Promise((resolve, reject) => {
      const token = this.instance();

      if (token) {
        cartsApi.show(token)
          .then(response => {
            this._storeCatItemCount(response.data);
            resolve(response)
          })
          .catch((error) => reject(error));
      } else {
        resolve();
      }
    });
  },

  updateCartQuantity(id, quantity) {
    const token = this.instance();

    const data = {
      quantity: quantity,
    };

    return cartsApi.updateItems(token, id, data);
  },

  destroyItem(id) {
    const token = this.instance();

    return cartsApi.destroyItems(token, id);
  },

  instance() {
    return localStorage.getItem("cToken");
  },

  saveInstance(token) {
    localStorage.setItem("cToken", token);
  },

  destroyInstance() {
    console.log("Destroy instance");
    localStorage.removeItem("cToken");
    this._emptyCart();
  },

  _storeCatItemCount(cart) {
    // store.dispatch("setCount", totalItemCount);
    store.dispatch("cart/setCart", cart);
  },

  _emptyCart() {
    store.dispatch("cart/setCount", 0);
  }
}