<template>
  <app-layout>
    <div class="mx-auto">
      <home-hero />
      <home-benefits />
      <home-how-it-works />
      <home-statistics />
      <home-cta-vendor />
    </div>
  </app-layout>
</template>

<script>
import AppLayout from "@/layout/AppLayout.vue";
import HomeHero from "@/components/HomeHero.vue";
import HomeStatistics from "../components/HomeStatistics.vue";
import HomeHowItWorks from "../components/HomeHowItWorks.vue";
import HomeBenefits from "../components/HomeBenefits.vue";
import HomeCtaVendor from '../components/HomeCtaVendor.vue';


export default {
  name: "Home",
  components: {
    AppLayout,
    HomeHero,
    HomeStatistics,
    HomeHowItWorks,
    HomeBenefits,
    HomeCtaVendor,

  },
};
</script>
