import { CARS } from "../endpoints";
import api from "../api";

export default {
  index(query) {
    let url = new URL(CARS);

    if (query !== null) url.search = new URLSearchParams(query).toString();

    return api.get(url);
  }
}