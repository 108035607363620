<template>
  <button
    class="
      text-yellow-600
      font-medium
      py-2
      px-4
      rounded
      border border-yellow-600
      hover:bg-yellow-600 hover:text-white
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style></style>
