export const API = process.env.VUE_APP_API;

//auth
export const LOGIN = API + "login";
export const LOGOUT = API + "logout";
export const REGISTER = API + "register";
export const PASSWORDS = API + "passwords";
export const ME = API + "me";

export const CARS = API + "cars";
export const MAKES = API + "car-makes";
export const MODELS = API + "car-models";
export const YEARS = API + "car-years";
export const CAR_STYLES = API + "car-styles";
export const CAR_FUEL_TYPES = API + "car-fuel-types";
export const COUNTRIES = API + "countries";
export const PAYMENT_METHODS = API + "payments/methods";
export const CUSTOMER_TYPES = API + "customer_types";

export const RFQS = API + "rfqs";
export const RFQS_SHOW = API + "rfqs/:rfq";
export const RFQS_QUOTATIONS = API + "rfqs/:rfq/quotations";
export const RFQ_CONFIRMATIONS = API + "rfq-confirmations"; // change it rfq-confirmations

//orders
export const ORDERS = API + "orders";
export const ORDERS_SHOW = API + "orders/:order";
export const ORDERS_ITEMS = API + "orders/:order/items";


export const CARTS = API + "carts";
export const CARTS_SHOW = API + "carts/:cart";
export const CARTS_ITEMS = API + "carts/:cart/items";
export const CARTS_ITEMS_SHOW = API + "carts/:cart/items/:item";

export const CHECKOUTS_THANKS = API + "checkout/thanks";
export const CHECKOUTS_ORDERS = API + "checkout/:cart/orders";

export const RATINGS = API + "ratings";