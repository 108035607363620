import Errors from "./Errors";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "http:///localhost";

class Form {
  loading = false;

  message = "";

  /**
   * Create a new Form instance.
   *
   * @param {object} data
   */
  constructor(data) {
    this.originalData = data;

    for (let field in data) {
      this[field] = data[field];
    }

    this.errors = new Errors();
  }

  /**
   * Fetch all relevant data for the form.
   */
  data() {
    let data = {};

    for (let property in this.originalData) {
      data[property] = this[property];
    }

    return data;
  }

  /**
   * Reset the form fields.
   */
  reset() {
    for (let field in this.originalData) {
      this[field] = this.originalData[field];
    }

    this.errors.clear();
  }

  setData(data) {
    for (let field in data) {
      if (Object.hasOwnProperty.call(this, field)) this[field] = data[field];
    }
  }

  /**
   * Send a POST request to the given URL.
   * .
   * @param {string} url
   */
  post(url) {
    return this.submit("post", url);
  }

  /**
   * Send a PUT request to the given URL.
   * .
   * @param {string} url
   */
  put(url) {
    return this.submit("put", url);
  }

  /**
   * Send a PATCH request to the given URL.
   * .
   * @param {string} url
   */
  patch(url) {
    return this.submit("patch", url);
  }

  /**
   * Send a DELETE request to the given URL.
   * .
   * @param {string} url
   */
  delete(url) {
    return this.submit("delete", url);
  }

  /**
   * Submit the form.
   *
   * @param {string} requestType
   * @param {string} url
   */
  submit(requestType, url) {
    return new Promise((resolve, reject) => {
      axios[requestType](url, this.data())
        .then((response) => {
          this.onSuccess(response.data);

          resolve(response.data);
        })
        .catch((error) => {
          this.onFail(error.response.data.errors);

          reject(error.response.data);
        });
    });
  }

  /**
   * Call the service method with the form data.
   *
   * @param {object} service
   * @param {promise} method
   * @param {object} extraArgs
   */
  submitService(service, method, extraArgs = null) {
    return this.processResponse(service[method](this.data(), extraArgs));
  }

  /**
   * Process the promise response
   *
   * @param {promise} promise
   */
  processResponse(promise) {
    this.loading = true;

    return new Promise((resolve, reject) => {
      promise
        .then((response) => {
          this.onSuccess(response);
          resolve(response);
          this.loading = false;
        })
        .catch((error) => {
          this.onFail(error.response.data);
          reject(error.response.data);
          this.loading = false;
        });
    });
  }

  /**
   * Handle a successful form submission.
   *
   * @param {object} data
   */
  onSuccess(data) {
    this.message = data.message;
    this.reset();
  }

  /**
   * Handle a failed form submission.
   *
   * @param {object} data
   */
  onFail(data) {
    this.message = data.message;
    this.errors.record(data.errors);
  }
}

export default Form;
