import { CAR_STYLES } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(CAR_STYLES);

        if (query) url.search = new URLSearchParams(query).toString();

        return api.get(url);
    }
}