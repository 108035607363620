export default {
    namespaced: true,

    state: {
        count: 0,
        items: [],
    },

    mutations: {

        setCount(state, count) {
            state.count = count;
        },

        setItems(state, items) {
            state.items = items
        },
    },

    actions: {
        setCount(context, count) {
            context.commit('setCount', count);
        },

        setCart(context, cart) {
            const totalItemCount = cart.items.reduce(
                (previousValue, currentValue) => {
                    return (previousValue += currentValue.quantity);
                },
                0
            );

            context.commit('setItems', cart.items);
            context.commit('setCount', totalItemCount);
        }
    },
}