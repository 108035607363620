<template>
  <div class="bg-white">
    <header
      class="
        flex
        justify-between
        container
        mx-auto
        px-4
        py-6
        border-b
        items-center
      "
    >
      <a href="/">
        <img class="h-6" src="../assets/logo.png" alt="pash logo" />
      </a>
      <div class="flex items-center">
        <a
          href="#"
          class="px-4 py-1 text-sm bg-purple-900 text-white rounded shadow-sm"
          >Browse</a
        >
        <MenuIcon v-if="!open" class="block h-6 w-6 ml-3" aria-hidden="true" />
      </div>
    </header>
    <!-- Hero -->
    <div class="container mx-auto px-4 py-10">
      <h1 class="text-4xl font-medium">Reliably Explore</h1>
      <h2 class="text-4xl font-thin mt-3">New Launch Properties</h2>
      <div class="flex py-6">
        <p>
          <span class="text-3xl font-semibold text-purple-900">145</span> <br />
          New Launches
        </p>
        <p class="ml-10">
          <span class="text-3xl font-semibold text-purple-900">346</span> <br />
          Unit Types
        </p>
        <p class="ml-10">
          <span class="text-3xl font-semibold text-purple-900">789</span> <br />
          Floor Plans
        </p>
      </div>
      <p class="text-gray-500 font-thin w-3/4 text-sm">
        Accurately listed & constantly updated by each project's officiale sale
        team
      </p>
    </div>
    <!-- Social proof-->
    <div class="flex bg-gray-50 p-4 justify-between">
      <img src="https://via.placeholder.com/100x50" alt="" class="py-6" />
      <img src="https://via.placeholder.com/100x50" alt="" class="py-6" />
      <img src="https://via.placeholder.com/100x50" alt="" class="py-6" />
    </div>
  </div>
</template>

<script>
import { MenuIcon } from "@heroicons/vue/outline";

export default {
  name: "Home",
  components: { MenuIcon },
};
</script>
