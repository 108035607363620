<template>
  <router-view />
</template>

<script>
import cartService from "./services/cartService";
export default {
  name: "App",

  mounted() {
    cartService.show();
  },
};
</script>

<style lang="scss"></style>
