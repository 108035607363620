import { CAR_FUEL_TYPES } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(CAR_FUEL_TYPES);

        if (query) url.search = new URLSearchParams(query).toString();

        return api.get(url);
    }
}