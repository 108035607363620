<template>
  <div class="bg-gray-100 min-h-screen flex flex-col">
    <app-layout-navbar />
    <main class="flex-1">
      <slot />
    </main>
    <app-layout-footer />
  </div>
</template>

<script>
import AppLayoutFooter from "./AppLayoutFooter.vue";
import AppLayoutNavbar from "./AppLayoutNavbar.vue";
export default {
  components: { AppLayoutNavbar, AppLayoutFooter },
};
</script>
