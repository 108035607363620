<template>
  <v-container>
    <header class="relative p-4 xl:px-0">
      <div class="flex justify-between items-start">
        <router-link class="w-auto" to="/">
          <img src="../assets/logo-column.png" alt="" width="120" />
        </router-link>

        <button
          type="button"
          class="
            lg:hidden
            inline-flex
            items-center
            justify-center
            p-2
            rounded-md
            text-gray-700
            hover:text-white hover:bg-gray-700
            focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
          "
          aria-controls="mobile-menu"
          aria-expanded="false"
          @click="toggleNavbar"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            v-if="!showMenu"
            class="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <svg
            v-if="showMenu"
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <nav class="hidden lg:flex items-center">
          <div class="flex-1 text-right">
            <div class="border-b-2 mb-3 text-black font-regular text-lg">
              <app-layout-navbar-link
                :to="{ name: 'carts.show' }"
                class="inline-flex items-center"
              >
                <div class="relative inline-flex mr-1 text-yellow-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-1 w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  <span
                    v-if="cartItemsCount"
                    class="
                      absolute
                      right-0
                      -top-1
                      rounded-full
                      bg-red-600
                      w-4
                      h-4
                      p-0
                      m-0
                      text-white
                      font-mono
                      text-xs
                      leading-xs
                      text-center
                    "
                  >
                    {{ cartItemsCount }}
                  </span>
                </div>
                Cosul meu
              </app-layout-navbar-link>
              <template v-if="isAuthenticated">
                <app-layout-navbar-link
                  :to="{ name: 'dashboard.show' }"
                  class="inline-flex items-center"
                >
                  <div class="relative inline-flex mr-1 text-yellow-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-1 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </div>
                  Contul meu
                </app-layout-navbar-link>
                <button
                  class="
                    hover:text-gray-500
                    px-3
                    py-2
                    rounded-md
                    text-sm
                    font-normal
                    block
                    inline-flex
                    items-center
                  "
                  @click.prevent="signOut"
                >
                  <div class="relative inline-flex mr-1 text-yellow-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-1 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                  </div>
                  Sign Out
                </button>
              </template>
              <template v-else>
                <app-layout-navbar-link
                  :to="{ name: 'login' }"
                  class="inline-flex items-center"
                >
                  <div class="relative inline-flex mr-1 text-yellow-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-1 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                      />
                    </svg>
                  </div>
                  Autentificare
                </app-layout-navbar-link>
                <app-layout-navbar-link
                  :to="{ name: 'register' }"
                  class="inline-flex items-center"
                >
                  <div class="relative inline-flex mr-1 text-yellow-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-1 w-5 h-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                      />
                    </svg>
                  </div>
                  Inregistrare
                </app-layout-navbar-link>
              </template>
            </div>
            <div class="flex flex-row justify-end">
              <app-layout-navbar-link
                v-for="link in navigation"
                :key="link.name"
                :to="link.to"
                >{{ link.name }}</app-layout-navbar-link
              >
            </div>
          </div>

          <router-link
            class="
              bg-yellow-600
              hover:bg-yellow-700
              text-white text-center
              py-10
              px-7
              md:ml-5
              rounded
              capitalize
              font-normal
              text-2xl
            "
            :to="{ name: 'rfqs.create' }"
            >Cere oferta</router-link
          >
        </nav>
      </div>

      <div v-if="showMenu">
        <nav
          class="
            absolute
            right-0
            mt-2
            bg-gray-100
            shadow-md
            w-full
            px-2
            pt-2
            pb-3
            space-y-1
          "
        >
          <app-layout-navbar-link :to="{ name: 'carts.show' }" class="flex">
            <div class="relative inline-flex mr-1 text-yellow-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-1 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span
                v-if="cartItemsCount"
                class="
                  absolute
                  right-0
                  -top-1
                  rounded-full
                  bg-red-600
                  w-4
                  h-4
                  p-0
                  m-0
                  text-white
                  font-mono
                  text-xs
                  leading-xs
                  text-center
                "
              >
                {{ cartItemsCount }}
              </span>
            </div>
            Cosul meu
          </app-layout-navbar-link>

          <app-layout-navbar-link
            v-for="link in navigation"
            :key="link.name"
            :to="link.to"
            >{{ link.name }}</app-layout-navbar-link
          >

          <app-layout-navbar-link
            class="
              bg-yellow-600
              hover:bg-yellow-700
              text-white text-center
              rounded
              capitalize
            "
            :to="{ name: 'rfqs.create' }"
            >Cere oferta</app-layout-navbar-link
          >
        </nav>
      </div>
    </header>
  </v-container>
</template>

<script>
import AppLayoutNavbarLink from "./AppLayoutNavbarLink.vue";
import VContainer from "../components/VContainer.vue";
import Cart from "../services/Cart";
import authService from "@/services/authService";

export default {
  components: {
    AppLayoutNavbarLink,
    VContainer,
  },

  data() {
    return {
      cart: new Cart(),
      showMenu: false,
      isAuthenticated: false,
      navigation: [
        { name: "Avantaje", to: { name: "Home", hash: "#benefits" } },
        {
          name: "Cum functioneaza",
          to: { name: "Home", hash: "#how-it-works" },
        },
        { name: "Devino partener", to: { name: "Home", hash: "#ctaVendor" } },
      ],
    };
  },

  computed: {
    cartItemsCount() {
      return this.$store.state.cart.count;
      // return this.cart.count();
    },
  },

  methods: {
    toggleNavbar() {
      this.showMenu = !this.showMenu;
    },

    signOut() {
      authService.logout().then(() => this.$router.push({ name: "login" }));
    },
  },

  created() {
    this.cart.load();
    this.isAuthenticated = authService.check();
  },
};
</script>

<style></style>
