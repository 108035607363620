<template>
  <label class="text-sm font-semibold text-gray-600">
    <slot></slot>
  </label>
</template>

<script>
export default {};
</script>

<style></style>
