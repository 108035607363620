<template>
  <section class="text-gray-600 body-font bg-white">
    <div class="container max-w-7xl mx-auto px-4 py-24">
      <div class="flex flex-col text-center w-full mb-20">
        <h2
          class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
        >
          Tu vii cu problema, noi venim cu piesa!
        </h2>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
          Simplu și rapid, te anunțam care sunt cele mai bune și de încredere
          oferte. Tu trebuie doar să accesezi platforma.
        </p>
      </div>
      <div class="flex flex-wrap -m-4 text-center">
        <div
          class="p-4 md:w-1/4 sm:w-1/2 w-full"
          v-for="stat in statistics"
          :key="stat.name"
        >
          <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
            <component
              :is="stat.icon"
              class="text-yellow-500 w-12 h-12 mb-3 inline-block"
              aria-hidden="true"
            />
            <h2 class="title-font font-medium text-3xl text-gray-900">
              {{ stat.name }}
            </h2>
            <p class="leading-relaxed">{{ stat.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  LightningBoltIcon,
  ScaleIcon,
  CreditCardIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/outline";

const statistics = [
  {
    name: "24",
    description: "ONLINE 24/24",
    icon: ScaleIcon,
  },
  {
    name: "+100",
    description: "PARCURI AUTO",
    icon: LightningBoltIcon,
  },
  {
    name: "+3000",
    description: "PIESE AUTO",
    icon: CreditCardIcon,
  },
  {
    name: "100%",
    description: "CLIENȚI SATISFĂCUȚI",
    icon: ShieldCheckIcon,
  },
];

export default {
  setup() {
    return {
      statistics,
    };
  },
};
</script>
