import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Estate from "../views/Estate.vue";
import authService from "../services/authService";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register.vue"),
    meta: {
      guest: true,
    },
  },
  {
    path: "/contul-meu",
    name: "dashboard.show",
    component: () =>
      import(/* webpackChunkName: "dashboard.show" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cereri",
    name: "rfqs.index",
    component: () =>
      import(/* webpackChunkName: "rfqs.index" */ "../views/RfqsIndex.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/comenzi",
    name: "orders.index",
    component: () =>
      import(/* webpackChunkName: "orders.index" */ "../views/OrdersIndex.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Comenzi", href: "/comenzi", current: true },
      ],
    },
  },

  {
    path: "/comenzi/:orderId",
    props: true,
    name: "orders.show",
    component: () =>
      import(/* webpackChunkName: "orders.show" */ "../views/OrdersShow.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: (route) => {
        return [
          { name: "Dashboard", href: "/contul-meu", current: false },
          { name: "Comenzi", href: "/comenzi", current: false },
          {
            name: `Comanda #${route.params.orderId}`,
            href: `/comenzi/${route.params.orderId}`,
            current: true,
          },
        ];
      },
    },
  },

  {
    path: "/setari",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
    meta: {
      requiresAuth: true,
      breadcrumbs: [
        { name: "Dashboard", href: "/contul-meu", current: false },
        { name: "Setari", href: "/settings", current: true },
      ],
    },
  },

  {
    path: "/estate",
    name: "Estate",
    component: Estate,
  },
  {
    path: "/cerere-oferta",
    name: "rfqs.create",
    component: () =>
      import(/* webpackChunkName: "rfqs.create" */ "../views/RfqsCreate.vue"),
  },
  {
    path: "/cerere-oferta/confirmare",
    name: "rfqs.confirmation",
    component: () =>
      import(
        /* webpackChunkName: "rfqs.confirmation" */ "../views/RfqsConfirmation.vue"
      ),
  },
  {
    path: "/cerere-oferta/:rfq",
    name: "rfqs.show",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "rfqs.show" */ "../views/RfqsShow.vue"
      ),
  },
  {
    path: "/cos-de-cumparaturi",
    name: "carts.show",
    component: () =>
      import(/* webpackChunkName: "carts.show" */ "../views/CartsShow.vue"),
  },
  {
    path: "/checkout/detalii-facturare",
    name: "checkout.billing",
    component: () =>
      import(
        /* webpackChunkName: "checkout.billing" */ "../views/CheckoutBilling.vue"
      ),
  },
  {
    path: "/checkout/detalii-livrare",
    name: "checkout.shipping",
    component: () =>
      import(
        /* webpackChunkName: "checkout.shipping" */ "../views/CheckoutShipping.vue"
      ),
  },
  {
    path: "/checkout/sumar-comanda",
    name: "checkout.summary",
    component: () =>
      import(
        /* webpackChunkName: "checkout.summary" */ "../views/CheckoutSummary.vue"
      ),
  },
  {
    path: "/checkout/va-multumim",
    name: "checkout.thanks",
    component: () =>
      import(
        /* webpackChunkName: "checkout.thanks" */ "../views/CheckoutThanks.vue"
      ),
  },
  {
    path: "/ratings",
    name: "ratings.create",
    component: () =>
      import(
        /* webpackChunkName: "ratings.create" */ "../views/RatingsCreate.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = {};
      if (to.hash) {
        position.selector = to.hash;
        if (document.querySelector(to.hash)) {
          return {
            el: position.selector,
            behavior: 'smooth',
          };
        }
        return false;
        // return { x: 0, y: 0 }
      }
    }

  },
});

//global guards
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.check()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!authService.check()) {
      next();
    } else {
      next({ name: "dashboard.show" });
    }
  } else {
    next(); //make sure to always call next!
  }
});

export default router;
