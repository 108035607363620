<template>
  <router-link
    class="hover:text-gray-500 px-3 py-2 rounded-md text-sm font-normal block"
    :to="to"
    ><slot></slot
  ></router-link>
</template>

<script>
export default {
  props: {
    to: { type: [String, Object] },
  },
};
</script>

<style></style>
