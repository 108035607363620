import { LOGIN, LOGOUT, REGISTER, ME } from "../endpoints";
import api from "../api";

export default {
    me() {
        return api.get(ME);
    },

    register(data) {
        return api.post(REGISTER, data);
    },

    login(data) {
        return api.post(LOGIN, data);
    },

    logout(data) {
        return api.post(LOGOUT, data);
    }
}