export default {
    namespaced: true,

    state: {
        user: {},
        email: ''
    },

    mutations: {
        setEmail(state, email) {
            state.email = email
        },

        setUser(state, user) {
            state.user = user
        }
    },

    actions: {
        setEmail(context, email) {
            context.commit('setEmail', email);
        },

        saveCredentials(context, credentials) {
            console.log(credentials);

            context.commit('setUser', credentials);
            context.commit('setEmail', credentials?.email);
        }
    }
}