class Rfq {
  warranty = false;
  return = false;
  pictures = false;
  items = [];
  email = "";
  nextPosition = 1;

  addItem(item) {
    this.load();

    item.position = this.nextPosition++;

    this.items.push(item);

    this.save();
  }

  clear() {
    if (localStorage.getItem("rfq") === null) {
      return;
    }

    localStorage.removeItem("rfq");
  }

  save() {
    localStorage.setItem("rfq", JSON.stringify(this));
  }

  load() {
    let rfq = localStorage.getItem("rfq");

    let parsedRfq = rfq === null ? new Rfq() : JSON.parse(rfq);

    for (let field in parsedRfq) {
      if (Object.hasOwnProperty.call(this, field)) {
        this[field] = parsedRfq[field];
      }
    }
  }

  removeItem(position) {
    this.load();

    this.items = this.items.filter((item) => item.position !== position);

    if (this.items.length === 0) {
      this.clear();
      return;
    }

    this.save();
  }
}

export default Rfq;
