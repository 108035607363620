<template>
  <textarea
    required=""
    name="message"
    id=""
    class="
      w-full
      appearance-none
      block
      bg-grey-lighter
      text-grey-darker
      border border-grey-lighter
      rounded-lg
      p-4
    "
    spellcheck="false"
    v-model="value"
  >
  </textarea>
</template>

<script>
export default {
  props: {
    modelValue: [String, Boolean],
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>

<style></style>
