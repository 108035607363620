<template>
  <input
    class="
      appearance-none
      block
      bg-grey-lighter
      text-grey-darker
      border border-grey-lighter
      rounded
      h-10
      px-4
    "
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    modelValue: [String, Boolean, Number],
  },
  emits: ["update:modelValue"],
};
</script>

<style></style>
