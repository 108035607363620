<template>
  <form action="" @submit.prevent="addPart">
    <div class="mb-6" v-text="form.message"></div>

    <div class="flex flex-col lg:flex-row lg:space-x-2">
      <div class="flex-1">
        <v-label for="make_id"> Marca </v-label>
        <select-make
          v-model="form.make_id"
          selectId="make_id"
          class="mb-2 block w-full"
          required
        />
      </div>
      <div class="flex-1">
        <v-label for="car_id"> Model </v-label>
        <select-car
          :makeId="form.make_id"
          v-model="form.car_id"
          selectId="car_id"
          class="mb-2 block w-full"
        />
      </div>
    </div>

    <div class="flex flex-col lg:flex-row lg:space-x-2">
      <div class="flex-1">
        <v-label for="car_style_id"> Tip caroserie </v-label>
        <select-car-style
          v-model="form.car_style_id"
          selectId="car_style_id"
          class="mb-2 block w-full"
          required
        />
      </div>
      <div class="flex-1">
        <v-label for="car_fuel_type_id"> Combustibil </v-label>
        <select-car-fuel-type
          v-model="form.car_fuel_type_id"
          selectId="car_fuel_type_id"
          class="mb-2 block w-full"
          required
        />
      </div>
    </div>

    <div class="flex flex-col lg:flex-row lg:space-x-2">
      <div class="flex-1">
        <v-label for="car_chassis_number">Serie sasiu</v-label>
        <v-input name="car_chassis_number" v-model="form.car_chassis_number" class="w-full mb-2" />
      </div>
      <div class="flex-1">
        <v-label>An</v-label>
        <v-input v-model="form.car_year" type="number" min="1900" max="2022" step="1" value="2022" class="w-full mb-2" />
      </div>
    </div>

    <div class="flex flex-col lg:flex-row lg:space-x-2">
      <div class="flex-1">
        <v-label for="name">Denumire</v-label>
        <v-input name="name" v-model="form.name" class="w-full mb-2" />
      </div>
      <div class="flex-1">
        <v-label>Cantitate</v-label>
        <v-input v-model="form.quantity" type="number" class="w-full mb-2" />
      </div>
    </div>

    <div class="flex flex-col lg:flex-row lg:space-x-2">
      <div class="flex-1">
        <div class="flex items-center mb-2">
          <v-label for="pictures">Poze</v-label>
          <v-checkbox class="ml-3" name="pictures" v-model="form.pictures" />
        </div>
        <div class="flex items-center mb-2">
          <v-label for="warranty">Garantie</v-label>
          <v-checkbox class="ml-3" name="warranty" v-model="form.warranty" />
        </div>
        <div class="flex items-center mb-2">
          <v-label for="return">Posibilitate de retur</v-label>
          <v-checkbox class="ml-3" name="return" v-model="form.return" />
        </div>
      </div>
      <div class="flex-1">
        <v-label>Codul piesei sau alte detalii</v-label>
        <v-textarea v-model="form.notes" class="mt-1" />
      </div>
    </div>

    <div class="mt-5 text-right">
      <button-outline-primary> Adauga la cerere </button-outline-primary>
    </div>
  </form>
</template>

<script>
import Form from "@/core/Form";
import Rfq from "@/Rfq";
import ButtonOutlinePrimary from "../ButtonOutlinePrimary.vue";
import SelectMake from "../SelectMake.vue";
import SelectCar from "../SelectCar.vue";
import VCheckbox from "../VCheckbox.vue";
import VLabel from "../ui/VLabel.vue";
import VInput from "../ui/VInput.vue";
import VTextarea from "../VTextarea.vue";
import SelectCarStyle from "../SelectCarStyle.vue";
import SelectCarFuelType from "../SelectCarFuelType.vue";

export default {
  components: {
    SelectMake,
    ButtonOutlinePrimary,
    VLabel,
    VCheckbox,
    SelectCar,
    VInput,
    VTextarea,
    SelectCarStyle,
    SelectCarFuelType,
  },
  data() {
    return {
      rfq: new Rfq(),
      form: new Form({
        name: "",
        quantity: "",
        notes: "",
        pictures: false,
        warranty: false,
        return: false,
        make_id: "",
        car_id: "",
        car_year: "",
        car_chassis_number: "",
        car_style_id: "",
        car_fuel_type_id: "",
      }),
    };
  },
  methods: {
    addPart() {
      this.$emit("create:item", this.form.data());
      this.form.reset();
    },
  },
};
</script>

<style></style>
