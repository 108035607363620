<template>
  <section class="text-gray-600 body-font" id="how-it-works">
    <div class="container max-w-7xl mx-auto px-4 py-24 flex flex-wrap">
      <div class="flex flex-col text-center w-full mb-20">
        <h2
          class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900"
        >
          Cum functioneaza plaftorma?
        </h2>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
          Urmeaza pasii simpli de mai jois si in cel mai scurt timp te vei bucura de o noua piesa.
        </p>
      </div>
      <div class="flex flex-wrap w-full">
        <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
          <div
            class="flex relative pb-12"
            v-for="step in steps"
            :key="step.name"
          >
            <div
              class="
                h-full
                w-10
                absolute
                inset-0
                flex
                items-center
                justify-center
              "
            >
              <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
            </div>
            <div
              class="
                flex-shrink-0
                w-10
                h-10
                rounded-full
                bg-yellow-500
                inline-flex
                items-center
                justify-center
                text-white
                relative
                z-10
              "
            >
              <component :is="step.icon" class="h-5 w-5" aria-hidden="true" />
            </div>
            <div class="flex-grow pl-4">
              <h2
                class="
                  font-medium
                  title-font
                  text-sm text-gray-900
                  mb-1
                  tracking-wider
                "
              >
                {{ step.name }}
              </h2>
              <p class="leading-relaxed">
                {{ step.description }}
              </p>
            </div>
          </div>

          <!-- To be removed -->
          <div class="flex relative">
            <div
              class="
                flex-shrink-0
                w-10
                h-10
                rounded-full
                bg-yellow-500
                inline-flex
                items-center
                justify-center
                text-white
                relative
                z-10
              "
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                <path d="M22 4L12 14.01l-3-3"></path>
              </svg>
            </div>
            <div class="flex-grow pl-4">
              <h2
                class="
                  font-medium
                  title-font
                  text-sm text-gray-900
                  mb-1
                  tracking-wider
                "
              >
                Final
              </h2>
              <p class="leading-relaxed">Bucura-te de piesa</p>
            </div>
          </div>
        </div>
        <div class="lg:w-3/5 md:w-1/2 md:mt-0 mt-12 self-center">
          <img
            class="object-cover object-center rounded-lg w-full"
            src="https://pash.ro/wp-content/uploads/2021/10/Automotive_aftermarket_excess_stock.jpg"
            alt="step"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  AnnotationIcon,
  LightningBoltIcon,
  CreditCardIcon,
  ClipboardCheckIcon,
  ShoppingBagIcon,
} from "@heroicons/vue/outline";

const steps = [
  {
    name: "Pasul 1",
    description: "Completeaza chestionarul cu piesele dorite.",
    icon: ClipboardCheckIcon,
  },
  {
    name: "Pasul 2",
    description: "Asteapta cotatii pe e-mail de la parcuri.",
    icon: LightningBoltIcon,
  },
  {
    name: "Pasul 3",
    description: "Vizualizeaza ofertele primite.",
    icon: AnnotationIcon,
  },
  {
    name: "Pasul 4",
    description: "Adauga produsul dorit in cos.",
    icon: ShoppingBagIcon,
  },
  {
    name: "Pasul 5",
    description: "Plaseaza comanda",
    icon: CreditCardIcon,
  },
];

export default {
  setup() {
    return {
      steps,
    };
  },
};
</script>
