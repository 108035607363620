import { CARTS, CARTS_SHOW, CARTS_ITEMS, CARTS_ITEMS_SHOW } from "../endpoints";
import api from "../api";

export default {
    show(cart) {
        const url = CARTS_SHOW.replace(":cart", cart);

        return api.get(url);
    },

    store(data = null) {
        if (data === null)
            return;

        return api.post(CARTS, data);
    },

    storeItems(cart = null, data = null) {
        if ((data === null) || (cart === null))
            return;

        const url = CARTS_ITEMS.replace(":cart", cart);

        return api.post(url, data);
    },

    updateItems(cart, item, data) {
        let url = CARTS_ITEMS_SHOW.replace(":cart", cart).replace(":item", item);

        return api.put(url, data);
    },
    
    destroyItems(cart, item) {

        let url = CARTS_ITEMS_SHOW.replace(":cart", cart).replace(":item", item);

        return api.delete(url);
    }
}