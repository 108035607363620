<template>
  <section class="text-gray-600 body-font" id="ctaVendor">
    <div class="container max-w-7xl mx-auto px-4 py-24">
      <div
        class="
          lg:w-2/3
          flex flex-col
          sm:flex-row sm:items-center
          items-start
          mx-auto
        "
      >
        <h2
          class="
            flex-grow
            sm:pr-16
            text-2xl
            font-medium
            title-font
            text-gray-900
          "
        >
          Simplu și rapid, te anunțam care sunt cele mai bune și de încredere
          oferte. Tu trebuie doar să accesezi platforma.
        </h2>
        <button
          class="
            flex-shrink-0
            text-white
            bg-yellow-500
            border-0
            py-2
            px-8
            focus:outline-none
            hover:bg-yellow-600
            rounded
            text-lg
            mt-10
            sm:mt-0
          "
        >
          Vreau sa vand pe Pash.ro
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {
  LightningBoltIcon,
  ScaleIcon,
  CreditCardIcon,
  ShieldCheckIcon,
} from "@heroicons/vue/outline";

const statistics = [
  {
    name: "24",
    description: "ONLINE 24/24",
    icon: ScaleIcon,
  },
  {
    name: "+100",
    description: "PARCURI AUTO",
    icon: LightningBoltIcon,
  },
  {
    name: "+3000",
    description: "PIESE AUTO",
    icon: CreditCardIcon,
  },
  {
    name: "100%",
    description: "CLIENȚI SATISFĂCUȚI",
    icon: ShieldCheckIcon,
  },
];

export default {
  setup() {
    return {
      statistics,
    };
  },
};
</script>
