import CartItem from "./CartItem";

class Cart {
  items = [];

  constructor(items = []) {
    this.items = items;
  }

  addItem(newItem) {
    let found = false;
    let items = this.items.map((item) => {
      if (item.item_id === newItem.item_id) {
        item.quantity++;
        found = true;
      }
      return item;
    });

    if (!found) items.push(newItem);

    this.items = items;

    this.save();
  }

  removeItem(id) {
    this.items = this.items.filter((item) => item.item_id !== id);

    this.save();
  }

  decrease(id, quantity = 1) {
    this.items = this.items
      .map((item) => {
        if (item.item_id === id) {
          item.quantity -= quantity;
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    this.save();
  }

  increase(id) {
    this.items = this.items.map((item) => {
      if (item.item_id === id) {
        item.quantity++;
      }
      return item;
    });

    this.save();
  }

  setQuantity(id, quantity) {
    let items = this.items
      .map((item) => {
        if (item.item_id === id) {
          item.quantity = quantity;
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    this.items = items;

    this.save();
  }

  save() {
    localStorage.setItem("cart", JSON.stringify(this));
  }

  clear() {
    localStorage.removeItem("cart");
  }

  load() {
    let cart = localStorage.getItem("cart");

    if (cart === null) return { items: this.items };

    let parsedCart = JSON.parse(cart);

    parsedCart.items = parsedCart.items.map(
      (item) => new CartItem(item.item_id, item.name, item.price, item.quantity)
    );

    this.setData(parsedCart);

    return parsedCart;
  }

  setData(data) {
    for (let field in data) {
      if (Object.hasOwnProperty.call(this, field)) this[field] = data[field];
    }
  }

  subtotal() {
    return this.items.reduce((previous, current) => {
      return previous + current.subtotal();
    }, 0);
  }

  tax() {
    return 0;
  }

  total() {
    return this.subtotal() + this.tax();
  }

  count() {
    return this.items.reduce((previous, current) => {
      return previous + current.quantity;
    }, 0);
  }
}

export default Cart;
